import './App.css';
import Layout from './Components/Layout/Layout';

function App() {
  return (
    
    <div className="App">
        <Layout/>
    </div>
  );
}

export default App;
